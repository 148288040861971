import $ from "jquery";
import naja from 'naja';
import netteForms from 'nette-forms';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

window.Nette = netteForms;

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));
netteForms.initOnLoad();

$(function(){
  console.log('hello world!!');
});

